import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import Navbar from './components/Navbar/Navbar';
import DeviceList from './pages/DeviceList';
import Charts from './pages/Charts';
import DeviceEdit from './pages/DeviceEdit';
import LoginPage from './pages/LoginPage';
import AnalyzePage from './pages/AnalyzePage';
import Notes from './pages/Notes';
import './index.css';

// Protected Route wrapper component
const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  return <>{children}</>;
};

const App: React.FC = () => {
  const { isLoggedIn } = useAuth();

  return (
    <Router>
      <div className="min-h-screen flex">
        {isLoggedIn && (
          <div className="flex-shrink-0">
            <Navbar />
          </div>
        )}
        <div
          className={`
            flex-grow
            overflow-auto
            ${isLoggedIn ? 'ml-1' : ''}
          `}
        >
          <div className="h-full w-full">
            <Routes>
              {/* Protected routes */}
              <Route path="/edit-device" element={
                <ProtectedRoute>
                  <div className="border-2">
                    <DeviceEdit />
                  </div>
                </ProtectedRoute>
              } />
              <Route path="/analyze" element={
                <ProtectedRoute>
                  <AnalyzePage />
                </ProtectedRoute>
              } />
              <Route path="/devices" element={
                <ProtectedRoute>
                  <DeviceList />
                </ProtectedRoute>
              } />
              <Route path="/charts" element={
                <ProtectedRoute>
                  <Charts />
                </ProtectedRoute>
              } />
              <Route path="/notes" element={
                <ProtectedRoute>
                  <Notes />
                </ProtectedRoute>
              } />

              {/* Public routes */}
              <Route path="/login" element={
                isLoggedIn ? <Navigate to="/analyze" replace /> : <LoginPage />
              } />

              {/* Default route */}
              <Route path="/" element={
                <Navigate to={isLoggedIn ? "/analyze" : "/login"} replace />
              } />

              {/* Catch all other routes */}
              <Route path="*" element={
                <Navigate to={isLoggedIn ? "/analyze" : "/login"} replace />
              } />
            </Routes>
          </div>
        </div>
      </div>
    </Router >
  );
};

export default App;