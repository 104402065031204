import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';
import config from '../utils/config';

interface Device {
  device_id: number;
  name: string;
  created_date: string;
  calibration_offset: number;
  centroids: null | any;
}

type DatePair = [string, string];

interface FilterDateSelectorProps {
  selectedDevice: string;
  onDateRangeSelect: (startDate: string, endDate: string) => void;
  disabled: boolean;
  devices: Device[];
  externalStartDate?: string;
  externalEndDate?: string;
}

const FilterDateSelector: React.FC<FilterDateSelectorProps> = ({
  selectedDevice,
  onDateRangeSelect,
  disabled,
  devices,
  externalStartDate,
  externalEndDate
}) => {
  const [filterDatePairs, setFilterDatePairs] = useState<DatePair[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedPeriodIndex, setSelectedPeriodIndex] = useState<string>('');

  useEffect(() => {
    const fetchFilterDates = async () => {
      if (!selectedDevice) {
        setFilterDatePairs([]);
        setSelectedPeriodIndex('');
        return;
      }

      setLoading(true);
      setError('');

      try {
        const device = devices?.find(d => d.name === selectedDevice);
        if (!device) {
          throw new Error('Device not found');
        }

        const response = await axiosInstance.get<DatePair[]>(
          `${config.notesApiUrl}/notes/filter_changes/${device.device_id}`
        );

        setFilterDatePairs(response.data);

        // Reset selection when device changes
        setSelectedPeriodIndex('');

        // Always select the most recent period (first in the array)
        if (response.data.length > 0) {
          const lastIndex = 0;
          setSelectedPeriodIndex(lastIndex.toString());
          const [startDate, endDate] = response.data[lastIndex];
          const formattedStartDate = new Date(startDate).toISOString().split('T')[0];
          const formattedEndDate = new Date(endDate).toISOString().split('T')[0];
          onDateRangeSelect(formattedStartDate, formattedEndDate);
        }
      } catch (err) {
        console.error('Error fetching filter dates:', err);
        setError('Failed to fetch filter change dates');
      } finally {
        setLoading(false);
      }
    };

    fetchFilterDates();
  }, [selectedDevice, devices]);

  // Effect to handle external date changes
  useEffect(() => {
    if (!externalStartDate || !externalEndDate || !selectedPeriodIndex || selectedPeriodIndex === '') {
      return;
    }

    const currentPair = filterDatePairs[parseInt(selectedPeriodIndex)];
    if (!currentPair) {
      return;
    }

    const [pairStart, pairEnd] = currentPair;
    const formattedPairStart = new Date(pairStart).toISOString().split('T')[0];
    const formattedPairEnd = new Date(pairEnd).toISOString().split('T')[0];

    // If the external dates don't match the selected period, reset the selection
    if (externalStartDate !== formattedPairStart || externalEndDate !== formattedPairEnd) {
      setSelectedPeriodIndex('');
    }
  }, [externalStartDate, externalEndDate, selectedPeriodIndex, filterDatePairs]);

  const handleFilterPeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newIndex = e.target.value;
    setSelectedPeriodIndex(newIndex);

    if (newIndex !== '' && filterDatePairs[parseInt(newIndex)]) {
      const [startDate, endDate] = filterDatePairs[parseInt(newIndex)];
      const formattedStartDate = new Date(startDate).toISOString().split('T')[0];
      const formattedEndDate = new Date(endDate).toISOString().split('T')[0];
      onDateRangeSelect(formattedStartDate, formattedEndDate);
    }
  };

  if (error) {
    return (
      <div className="text-red-500 text-sm mt-2">
        {error}
      </div>
    );
  }

  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium">
        Filter Change Periods
      </label>
      <select
        onChange={handleFilterPeriodChange}
        className="w-full p-2 border rounded-md disabled:bg-gray-100"
        value={selectedPeriodIndex}
        disabled={disabled || loading || !selectedDevice || filterDatePairs.length === 0}
      >
        <option value="">Select a filter period</option>
        {filterDatePairs.map((pair, index) => {
          const startDate = new Date(pair[0]).toLocaleDateString();
          const endDate = new Date(pair[1]).toLocaleDateString();
          return (
            <option key={index} value={index.toString()}>
              {startDate} to {endDate}
            </option>
          );
        })}
      </select>
      {loading && (
        <div className="text-gray-500 text-sm">
          Loading filter periods...
        </div>
      )}
    </div>
  );
};

export default FilterDateSelector;