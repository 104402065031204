import React, { useState, useEffect } from 'react';
import config from '../utils/config'
import { useAuth } from '../context/AuthContext';
import PressureAnalysisDisplay from '../components/PressureAnalysisDisplay';
import axiosInstance from '../utils/axiosInstance';
import FilterDateSelector from '../components/FilterDateSelector';
import {
  AnalysisResults,
  Device,
  AnalysisRequest,
  DownloadCSVRequest,
  OldestReadingResponse
} from '../types';

// Types
interface LogEntry {
  timestamp: string;
  level: string;
  message: string;
}

interface AnalysisError {
  response?: {
    data?: {
      error?: string;
    };
  };
}

const yesterday = () => {
  const yesterday = new Date(new Date().setHours(0, 0, 0, 0));
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday.toLocaleDateString('en-CA'); // YYYY-MM-DD format
}

// Get the oldest reading date for a device from API
const getOldestPressureReading = async (device_name: string): Promise<any> => {
  // const response = await fetch(`${config.analyzeApiUrl}/analyze/oldest_reading?device_name=${device_name}`);
  // if (!response.ok) {
  //   throw new Error(`HTTP error! status: ${response.status}`);
  // }
  const response = await axiosInstance.get<OldestReadingResponse>(`${config.analyzeApiUrl}/analyze/oldest_reading?device_name=${device_name}`);
  // Get the oldest date from the response in the 'date' field
  // const data = await response.json();
  const data = response.data;
  const date_obj = new Date(data.date);
  const date = date_obj.toLocaleDateString('en-CA'); // YYYY-MM-DD format
  return date;
};

const AnalyzePage = () => {
  const [useCached, setUseCached] = useState(true);
  const [devices, setDevices] = useState<Device[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<Device | undefined>(undefined);
  const [aggregationType, setAggregationType] = useState<'max' | 'avg' | 'median'>('max');
  const [readingsConsidered, setReadingsConsidered] = useState<'all_high_fan' | 'last_30_high_fan'>('all_high_fan');
  const [forecastMethod, setForecastMethod] = useState<'linear' | 'kalman'>('linear');
  const [generateCharts, setGenerateCharts] = useState(true);
  const [startDate, setStartDate] = useState(yesterday);
  const [endDate, setEndDate] = useState(yesterday);
  const [oldestDate, setOldestDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingOldestDate, setLoadingOldestDate] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [analysisResults, setAnalysisResults] = useState<AnalysisResults | null>(null);
  const [processNoise, setProcessNoise] = useState('0.0001'); // 1e-4 default
  const [measurementNoise, setMeasurementNoise] = useState('0.01'); // 1e-2 default

  const { account_id } = useAuth();

  const deviceFromName = (name: string) => devices.find((device) => device.name === name);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await axiosInstance.get<Device[]>(`${config.devicesApiUrl}/devices`);
        const sortedDevices = [...response.data].sort((a, b) =>
          a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
        );
        setDevices(sortedDevices);
      } catch (error) {
        console.error('Error fetching devices:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDevices();
  }, []);

  const handleDateRangeSelect = (start: string, end: string) => {
    console.log('Date range selected:', start, end);
    setStartDate(start);
    setEndDate(end);
  };

  const handleDeviceChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const device_name = e.target.value;
    const device = deviceFromName(device_name);
    setSelectedDevice(device);
    setStartDate(''); // Clear date while loading
    setAnalysisResults(null);
    setSuccess(false);
    setError('');

    if (device_name) {
      setLoadingOldestDate(true);
      try {
        const oldest_date = await getOldestPressureReading(device_name);
        console.log('Oldest date:', oldest_date);
        setOldestDate(oldest_date);
        console.log('Start date:', startDate);
        console.log('Oldest date:', oldest_date);
      } catch (error) {
        console.error('Error fetching oldest date:', error);
        setError('Failed to fetch oldest reading date');
      } finally {
        setLoadingOldestDate(false);
      }
    }
  };

  const handleDownloadCSV = async () => {
    setError('');
    setLoading(true);
    if (!selectedDevice?.name) return;

    try {
      const downloadRequest: DownloadCSVRequest = {
        device_name: selectedDevice.name,
        start_date: startDate,
        end_date: endDate
      };

      const response = await axiosInstance.post<Blob>(
        `${config.analyzeApiUrl}/analyze/download_csv`,
        downloadRequest,
        { responseType: 'blob' }
      );

      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${selectedDevice.name}_pressure_data.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading CSV:', error);
      setError('Failed to download CSV');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess(false);
    setAnalysisResults(null);

    const analysisData: AnalysisRequest = {
      use_cached: useCached,
      only_cached: false,
      device_name: selectedDevice?.name || '',
      agg: aggregationType,
      readings_considered: readingsConsidered,
      forecast_method: forecastMethod,
      generate_charts: generateCharts,
      account_id: account_id,
      start_date: startDate,
      end_date: endDate,
    };
    if (forecastMethod === 'kalman') {
      analysisData.process_noise = Number(processNoise);
      analysisData.measurement_noise = Number(measurementNoise);
    }

    const pollInterval = 2000;
    const maxAttempts = 60;
    let attempts = 0;

    try {
      while (attempts < maxAttempts) {

        const response = await axiosInstance.post<AnalysisResults>(`${config.analyzeApiUrl}/analyze/filter_change`, analysisData);

        if (!response) {
          throw new Error('Analysis failed');
        }
        const data = response.data;

        // If we got the final results, break out of the polling loop
        if (data.status !== 'working') {
          try {
            // Validate the response shape
            const typedResponse = data as AnalysisResults;
            console.log('Validation succeeded');
            setAnalysisResults(typedResponse);
          } catch (error) {
            console.error('Type validation failed:', error);
            console.log('Raw response:', response.data);
            throw new Error('Analysis results could not be parsed');
          }
          // setAnalysisResults(data);
          setSuccess(true);
          break;
        }
        // Next iteration will use cached results from the API
        analysisData.only_cached = true;
        // Still working, increment attempts and wait before next poll
        attempts++;
        if (attempts >= maxAttempts) {
          throw new Error('Analysis timed out after 2 minutes');
        }

        await new Promise(resolve => setTimeout(resolve, pollInterval));
      }
    } catch (err: unknown) {
      const axiosError = err as { response?: { data?: { error?: string } } };
      if (axiosError?.response?.data?.error) {
        const formattedError = axiosError.response.data.error.replace(/\n\s*\n/g, '\n');
        setError(`Analysis failed: ${formattedError}`);
      } else {
        setError('Analysis failed. Please try again.');
      }
      console.error('Error during analysis:', err);
    } finally {
      setLoading(false);
    }
  };
  const isFormDisabled = loading || loadingOldestDate;
  // const equation = selectedDevice?.

  return (
    <div className="h-full w-full">
      <div className="max-w-7xl mx-auto p-2">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-bold mb-2">Analyze Filter Change</h2>

          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {/* Column 1: Device Selection */}
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium mb-2">Device</label>
                  <select
                    name="device"
                    value={selectedDevice?.name || ''}
                    onChange={handleDeviceChange}
                    className="w-full p-2 border rounded-md"
                  >
                    <option value="">Select a device</option>
                    {devices.map((device) => (
                      <option key={device.device_id} value={device.name}>
                        {device.name}
                      </option>
                    ))}
                  </select>
                </div>
                {selectedDevice && (
                  <div className="border border-dashed border-gray-200 rounded-md">
                    Pressures:&nbsp;&nbsp;
                    {selectedDevice.x46_fanside ? 'x47 - x46 ' : 'x46 - x47 '}
                    {selectedDevice.calibration_offset < 0.0
                      ? `+ ${Math.abs(selectedDevice.calibration_offset)} Pa`
                      : `- ${selectedDevice.calibration_offset} Pa`}
                  </div>
                )}

                <div className="flex items-center justify-between">
                  <label className="text-sm font-medium">Use Cached Results</label>
                  <input
                    type="checkbox"
                    name="useCached"
                    checked={useCached}
                    onChange={(e) => setUseCached(e.target.checked)}
                    className="h-4 w-4"
                    disabled={isFormDisabled}
                  />
                </div>

                <div className="flex items-center justify-between">
                  <label className="text-sm font-medium">Generate Charts</label>
                  <input
                    type="checkbox"
                    name="generateCharts"
                    checked={generateCharts}
                    onChange={(e) => setGenerateCharts(e.target.checked)}
                    className="h-4 w-4"
                    disabled={isFormDisabled}
                  />
                </div>

                <button
                  type="button"
                  onClick={handleDownloadCSV}
                  disabled={!selectedDevice}
                  className="text-blue-500 hover:text-blue-700 text-sm"
                >
                  Download CSV
                </button>

              </div>

              {/* Column 2: Analysis Options */}
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium mb-2">Daily Readings Considered</label>
                  <select
                    name="readingsConsidered"
                    value={readingsConsidered}
                    onChange={(e) => setReadingsConsidered(e.target.value as 'all_high_fan' | 'last_30_high_fan')}
                    className="w-full p-2 border rounded-md"
                    disabled={isFormDisabled || !selectedDevice}
                  >
                    <option value="all_high_fan">All High Fan Readings</option>
                    <option value="last_30_high_fan">Last 30 High Fan Readings</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium mb-2">Daily Reading Aggregation</label>
                  <select
                    name="aggregationType"
                    value={aggregationType}
                    onChange={(e) => setAggregationType(e.target.value as 'max' | 'avg' | 'median')}
                    className="w-full p-2 border rounded-md"
                    disabled={isFormDisabled || !selectedDevice}
                  >
                    <option value="max">Max</option>
                    <option value="avg">Avg</option>
                    <option value="median">Median</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium mb-2">Forecast Method</label>
                  <select
                    name="forecastMethod"
                    value={forecastMethod}
                    onChange={(e) => setForecastMethod(e.target.value as 'linear' | 'kalman')}
                    className="w-full p-2 border rounded-md"
                    disabled={isFormDisabled || !selectedDevice}
                  >
                    <option value="linear">Linear</option>
                    {/* <option value="kalman">Kalman</option> */}
                  </select>
                </div>

                {forecastMethod === 'kalman' && (
                  <>
                    <div>
                      <label
                        className="block text-sm font-medium mb-2 cursor-help"
                        title="Process Noise represents how much the true pressure state can change between measurements. Higher values make the forecast more responsive to recent changes but more volatile. Lower values create a more stable, smooth forecast that changes more slowly."
                      >
                        Process Noise
                      </label>
                      <select
                        name="processNoise"
                        value={processNoise}
                        onChange={(e) => setProcessNoise(e.target.value)}
                        className="w-full p-2 border rounded-md"
                        disabled={isFormDisabled || !selectedDevice}
                        title="Higher values (e.g., 1e-2) make the forecast more responsive but potentially noisy. Lower values (e.g., 1e-6) create a more stable forecast that changes slowly."
                      >
                        <option value="0.000001">1e-6</option>
                        <option value="0.00001">1e-5</option>
                        <option value="0.0001">1e-4 (Default)</option>
                        <option value="0.001">1e-3</option>
                        <option value="0.01">1e-2</option>
                      </select>
                    </div>

                    <div>
                      <label
                        className="block text-sm font-medium mb-2 cursor-help"
                        title="Measurement Noise represents how much you trust each individual pressure reading. Higher values assume more noise in the measurements, leading to a forecast that changes more gradually. Lower values make the forecast more responsive to each measurement."
                      >
                        Measurement Noise
                      </label>
                      <select
                        name="measurementNoise"
                        value={measurementNoise}
                        onChange={(e) => setMeasurementNoise(e.target.value)}
                        className="w-full p-2 border rounded-md"
                        disabled={isFormDisabled || !selectedDevice}
                        title="Higher values (e.g., 1e0) assume more noisy measurements, creating a smoother forecast. Lower values (e.g., 1e-4) make the forecast more responsive to individual measurements."
                      >
                        <option value="0.0001">1e-4</option>
                        <option value="0.001">1e-3</option>
                        <option value="0.01">1e-2 (Default)</option>
                        <option value="0.1">1e-1</option>
                        <option value="1">1e0</option>
                      </select>
                    </div>
                  </>
                )}
              </div>

              {/* Column 3: Date Selection and Checkboxes */}
              <div className="space-y-4">

                <FilterDateSelector
                  selectedDevice={selectedDevice?.name || ''}
                  onDateRangeSelect={handleDateRangeSelect}
                  disabled={isFormDisabled}
                  devices={devices}
                  externalStartDate={startDate}
                  externalEndDate={endDate}
                />

                <div className="space-y-4">
                  <div>
                    <label htmlFor="startDate" className="block text-sm font-medium mb-2">Start Date</label>
                    <input
                      id="startDate"
                      name="startDate"
                      type="date"
                      className="w-full p-2 border rounded-md"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      disabled={!selectedDevice || isFormDisabled}
                      min={oldestDate}
                      max={yesterday()}
                    />
                  </div>
                  <div>
                    <label htmlFor="endDate" className="block text-sm font-medium mb-2">End Date</label>
                    <input
                      id="endDate"
                      name="endDate"
                      type="date"
                      className="w-full p-2 border rounded-md"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      disabled={!selectedDevice || isFormDisabled}
                      min={oldestDate}
                      max={yesterday()}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Status Messages */}
            <div className="mt-6">
              {error && (
                <div className="bg-red-50 text-red-500 p-3 rounded-md whitespace-pre-line mb-4">
                  {error}
                </div>
              )}

              {success && (
                <div className="bg-green-50 text-green-500 p-3 rounded-md mb-4">
                  Analysis completed successfully!
                </div>
              )}

              <button
                type="submit"
                disabled={!selectedDevice || isFormDisabled}
                className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
              >
                {loading ? 'Analyzing...' : loadingOldestDate ? 'Loading...' : 'Start Analysis'}
              </button>
            </div>
          </form>
        </div>
      </div>

      {analysisResults && <PressureAnalysisDisplay data={analysisResults} />}
    </div>
  );
};

export default AnalyzePage;