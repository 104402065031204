import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { ChartData } from '../types';

interface TempHumidityChartProps {
  jsonData: ChartData;
}

const toFarenheit = (celsius: number) => {
  return celsius * 9 / 5 + 32;
}

const TempHumidityChart: React.FC<TempHumidityChartProps> = ({ jsonData }) => {
  if (!jsonData) return null;

  const chartData = jsonData.dates.map((date, i) => ({
    date,
    temperature: toFarenheit(jsonData.m_temperatures[i]),
    humidity: jsonData.m_humidities[i]
  }));

  const formatTooltip = (value: ValueType, name: string) => {
    if (value === null || value === undefined) return ['-', name];

    if (name === 'Temperature') {
      return [`${value}°F`, name];
    }
    if (name === 'Humidity') {
      return [`${value}%`, name];
    }
    return [value, name];
  };

  return (
    <div className="w-full mt-4">
      <h3 className="text-lg font-semibold mb-8">Fanside Temperature and Humidity</h3>
      <div className="flex items-start gap-4">
        <div className="flex-1" style={{ height: '400px' }}>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={chartData}
              margin={{ top: 10, right: 90, left: 50, bottom: 60 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tick={{ fontSize: 12 }}
                angle={-45}
                textAnchor="end"
                height={50}
              />
              <YAxis
                yAxisId="temperature"
                domain={['auto', 'auto']}
                label={{
                  value: 'Temperature (°F)',
                  angle: -90,
                  position: 'insideLeft',
                  offset: -35
                }}
              />
              <YAxis
                yAxisId="humidity"
                orientation="right"
                domain={[0, 100]}
                label={{
                  value: 'Humidity (%)',
                  angle: 90,
                  position: 'insideRight',
                  offset: -35
                }}
              />
              <Tooltip formatter={formatTooltip} />
              <Legend verticalAlign="top" height={20} />
              <Line
                type="monotone"
                dataKey="temperature"
                stroke="#dc2626"
                dot={{ r: 2 }}
                name="Temperature"
                yAxisId="temperature"
                strokeWidth={2}
                connectNulls={true}
              />
              <Line
                type="monotone"
                dataKey="humidity"
                stroke="#2563eb"
                dot={{ r: 2 }}
                name="Humidity"
                yAxisId="humidity"
                strokeWidth={2}
                connectNulls={true}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default TempHumidityChart;