import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  ComposedChart
} from 'recharts';
import { ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { ChartData } from '../types';
import { json } from 'stream/consumers';

interface RuntimeChartProps {
  jsonData: ChartData
}

const RuntimeChart: React.FC<RuntimeChartProps> = ({ jsonData }) => {
  if (!jsonData) return null;

  // Debug incoming data
  console.log("Temperature data:", jsonData.temperatures);

  const chartData = jsonData.dates.map((date, i) => {
    // Debug mapping
    console.log(`Mapping date ${date}:`, {
      maxTemp: jsonData.temperatures?.max?.[i],
      minTemp: jsonData.temperatures?.min?.[i]
    });

    const dataPoint = {
      date,
      runtime: jsonData.runtimes[i],
      maxTemp: jsonData.temperatures?.max?.[i] ?? null,
      minTemp: jsonData.temperatures?.min?.[i] ?? null,
    };
    return dataPoint;
  });

  // Find valid temperature ranges
  const validTemps = chartData.reduce((temps, point) => {
    // Debug each point
    console.log("Checking point:", point);

    if (point.maxTemp !== null && point.maxTemp !== undefined) temps.push(point.maxTemp);
    if (point.minTemp !== null && point.minTemp !== undefined) temps.push(point.minTemp);
    return temps;
  }, [] as number[]);

  // Calculate domain first, then check if we should show temperatures
  const tempDomain = validTemps.length > 0 ? {
    min: Math.floor(Math.min(...validTemps)),
    max: Math.ceil(Math.max(...validTemps))
  } : null;

  // Check if we have temperature data and a valid domain
  const hasTemperatures = tempDomain !== null;

  console.log("Temp Domain", tempDomain);
  console.log("Valid Temps", validTemps);

  // Add padding if we have a valid domain
  const tempPadding = tempDomain ? Math.max(5, (tempDomain.max - tempDomain.min) * 0.1) : 0;

  const formatTooltip = (value: ValueType, name: string) => {
    if (value === null || value === undefined) return ['-', name];

    if (name === 'Daily Runtime') {
      const minutes = typeof value === 'number' ? value.toFixed(0) : value;
      const hours = (Number(minutes) / 60).toFixed(1);
      return [`${minutes} min (${hours} hrs)`, 'Runtime'];
    }

    // Temperature formatting
    if (name === 'Max Temperature' || name === 'Min Temperature') {
      return [`${value}°F`, name];
    }

    return [value, name];
  };

  // Log how many points we have for runtimes and temperatures
  console.log("Pressure points:", jsonData.runtimes.length);
  console.log("Temperature points:", jsonData.temperatures?.max?.length);
  console.log("Temperature points:", jsonData.temperatures?.min?.length);

  return (
    <div className="w-full mt-4 mb-8">
      <h3 className="text-lg font-semibold mb-8">Daily Runtimes and Outdoor Temperatures</h3>
      <div className="flex items-start gap-4">
        <div className="flex-1" style={{ height: '300px' }}>
          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart
              data={chartData}
              margin={{ top: 10, right: 90, left: 50, bottom: 60 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tick={{ fontSize: 12 }}
                angle={-45}
                textAnchor="end"
                height={50}
              />
              {/* Runtime Y-axis (left) */}
              <YAxis
                yAxisId="runtime"
                domain={[0, 'auto']}
                label={{
                  value: 'Runtime (minutes)',
                  angle: -90,
                  position: 'insideLeft',
                  offset: -35,
                  style: { textAnchor: 'middle' }
                }}
              />
              {/* Runtime Y-axis (first right) */}
              <YAxis
                yAxisId="right"
                orientation="right"
                domain={[0, 'auto']}
                label={{
                  value: 'Runtime (hours)',
                  angle: 90,
                  position: 'insideRight',
                  offset: -35,
                  style: { textAnchor: 'middle' }
                }}
                tickFormatter={(val: number) => (val / 60).toFixed(1)}
              />
              {/* Temperature Y-axis (only render if we have temperature data) */}
              {hasTemperatures && tempDomain && (
                <YAxis
                  yAxisId="temperature"
                  orientation="right"
                  label={{
                    value: 'Temperature (°F)',
                    angle: 90,
                    position: 'insideRight',
                    offset: 75,
                    style: { textAnchor: 'middle' }
                  }}
                  domain={[tempDomain.min - tempPadding, tempDomain.max + tempPadding]}
                />
              )}
              <Tooltip formatter={formatTooltip} />
              <Legend verticalAlign="top" height={20} />
              <Bar
                dataKey="runtime"
                fill="#2563eb"
                name="Daily Runtime"
                yAxisId="runtime"
              />
              {hasTemperatures && (
                <>
                  <Line
                    type="monotone"
                    dataKey="maxTemp"
                    stroke="#dc2626"
                    name="Max Temperature"
                    yAxisId="temperature"
                    dot={true}  // Changed to true to debug data points
                    connectNulls={true}
                  />
                  <Line
                    type="monotone"
                    dataKey="minTemp"
                    stroke="#059669"
                    name="Min Temperature"
                    yAxisId="temperature"
                    dot={true}  // Changed to true to debug data points
                    connectNulls={true}
                  />
                </>
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default RuntimeChart;