import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer
} from 'recharts';
import { ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { DataPoint, KalmanResults, ChartData } from '../types';

interface PressureChartProps {
  jsonData: ChartData;
  kalmanData?: KalmanResults;
}

const PressureChart: React.FC<PressureChartProps> = ({ jsonData, kalmanData }) => {
  if (!jsonData) return null;
  // use kalmanRevised for actually displaying the data
  let kalmanRevised: number[] = [];
  if (kalmanData && kalmanData?.kalman_predict_history?.length > 0 && Array.isArray(kalmanData.kalman_predict_history)) {
    kalmanRevised = [kalmanData.kalman_predict_history[0], kalmanData.kalman_predict_history[0], ...kalmanData.kalman_predict_history];
  }
  if (kalmanData && kalmanRevised.length !== jsonData.dates.length) {
    console.warn(`Length mismatch: kalmanRevised (${kalmanRevised.length}) vs dates (${jsonData.dates.length})`);
  }

  const chartData: DataPoint[] = jsonData.dates.map((date, i) => {
    const filteredIndex = jsonData.filtered_dates.indexOf(date);
    const isFiltered = filteredIndex >= 0;

    // Calculate trend for all points using the global slope and intercept
    const trend = jsonData.trend_line.slope * i + jsonData.trend_line.intercept;

    return {
      date,
      pressure: jsonData.pressures[i],
      filtered: isFiltered ? jsonData.filtered_pressures[filteredIndex] : null,
      trend,
      kalmanPrediction: kalmanRevised[i] || null
    };
  });

  const formatTooltip = (value: ValueType, name: string) => {
    if (value === null || value === undefined) return ['-', name];
    const val = typeof value === 'number' ? value.toFixed(2) : value;
    const label = name;
    return [`${val} Pa`, label];
  };

  return (
    <div className="w-full mt-4">
      <div className="flex items-start gap-4">
        <div className="flex-1" style={{ height: '500px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={chartData} margin={{ top: 20, right: 50, left: 20, bottom: 60 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tick={{ fontSize: 12 }}
                angle={-45}
                textAnchor="end"
                height={70}
              />
              <YAxis
                yAxisId="left"
                domain={['auto', 'auto']}
                label={{ value: 'Pressure (Pa)', angle: -90, position: 'insideLeft' }}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                domain={['auto', 'auto']}
                label={{ value: 'Pressure (inH₂O)', angle: 90, position: 'insideRight' }}
                tickFormatter={(val: number) => (val * 0.004014).toFixed(2)}
              />
              <Tooltip formatter={formatTooltip} />
              <Legend
                verticalAlign="bottom"
                height={36}
                wrapperStyle={{
                  paddingTop: '20px'
                }}
              />
              <ReferenceLine
                y={jsonData.targets.notice}
                stroke="orange"
                strokeDasharray="3 3"
                label={{ value: 'Notice Target', position: 'right' }}
                yAxisId="left"
              />
              <ReferenceLine
                y={jsonData.targets.change}
                stroke="red"
                strokeDasharray="3 3"
                label={{ value: 'Change Target', position: 'right' }}
                yAxisId="left"
              />
              <Line
                type="monotone"
                dataKey="pressure"
                stroke="#999"
                dot={{ r: 2 }}
                name="All measurements"
                yAxisId="left"
                strokeWidth={1}
                connectNulls={true}
              />
              <Line
                type="monotone"
                dataKey="filtered"
                stroke="#2563eb"
                dot={{ r: 2 }}
                name="Measurements Used"
                yAxisId="left"
                strokeWidth={2}
                connectNulls={true}
              />
              <Line
                type="monotone"
                dataKey="trend"
                stroke="#dc2626"
                dot={false}
                strokeDasharray="5 5"
                name="Trend"
                yAxisId="left"
                strokeWidth={2}
                connectNulls={true}
              />
              {kalmanData && (
                <Line
                  type="monotone"
                  dataKey="kalmanPrediction"
                  stroke="#ff7300"
                  dot={false}
                  name="Kalman Prediction"
                  yAxisId="left"
                  strokeWidth={2}
                  connectNulls={true}
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default PressureChart;