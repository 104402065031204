import React, { useState, useEffect, useCallback, useRef } from 'react';
import axiosInstance from '../utils/axiosInstance';
import config from '../utils/config';
import { useAuth } from '../context/AuthContext';
import { FaTrash, FaFilter, FaCalendar, FaUser } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/datetime.css';
import ImageModal from './ImageModal';

interface Device {
  device_id: number;
  name: string;
}

interface Note {
  id: number;
  device_id: number;
  account_id: number;
  note_text: string;
  created_at: string;
  creator_email: string;
  filter_changed: boolean;
  image_url?: string;
}

const DeviceNotes: React.FC = () => {
  const [devices, setDevices] = useState<Device[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState<number | null>(null);
  const [notes, setNotes] = useState<Note[]>([]);
  const [newNoteText, setNewNoteText] = useState('');
  const [filterChanged, setFilterChanged] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState<Date | null>(new Date());
  const [loading, setLoading] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const { account_id } = useAuth();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get<Device[]>(`${config.devicesApiUrl}/devices`);
        const sortedDevices = response.data.sort((a, b) => a.name.localeCompare(b.name));
        setDevices(sortedDevices);
      } catch (error) {
        console.error('Error fetching devices:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDevices();
  }, []);

  useEffect(() => {
    if (selectedDeviceId !== null) {
      const fetchNotes = async () => {
        try {
          setLoading(true);
          const response = await axiosInstance.get<Note[]>(`${config.notesApiUrl}/notes/device/${selectedDeviceId}`);
          setNotes(response.data);
        } catch (error) {
          console.error('Error fetching notes:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchNotes();
    } else {
      setNotes([]);
    }
  }, [selectedDeviceId]);

  const formatLocalDateTime = (dbDateTime: string) => {
    const date = new Date(dbDateTime);
    return date.toLocaleString(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
  };

  const handleDrag = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  }, []);

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const file = e.dataTransfer.files?.[0];
    if (file && file.type.startsWith('image/')) {
      setImageFile(file);
      const preview = URL.createObjectURL(file);
      setImagePreview(preview);
    }
  }, []);

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file.type.startsWith('image/')) {
      setImageFile(file);
      const preview = URL.createObjectURL(file);
      setImagePreview(preview);
    }
  };

  const handleSelectFileClick = () => {
    fileInputRef.current?.click();
  };

  const handleImageClear = useCallback(() => {
    if (imagePreview) {
      URL.revokeObjectURL(imagePreview);
    }
    setImageFile(null);
    setImagePreview(null);
  }, [imagePreview]);

  const handleAddNote = async () => {
    if (newNoteText.trim() === '' || selectedDeviceId === null) return;

    const formData = new FormData();
    formData.append('device_id', selectedDeviceId.toString());
    formData.append('account_id', account_id.toString());
    formData.append('note_text', newNoteText.trim());
    formData.append('filter_changed', filterChanged.toString());
    formData.append('created_at', selectedDateTime?.toISOString() || new Date().toISOString());

    if (imageFile) {
      formData.append('image', imageFile);
    }

    try {
      setLoading(true);
      const response = await axiosInstance.post<Note>(
        `${config.notesApiUrl}/notes`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      setNotes([...notes, response.data].sort((b, a) => a.created_at.localeCompare(b.created_at)))
      setNewNoteText('');
      setFilterChanged(false);
      setSelectedDateTime(new Date());
      handleImageClear();
    } catch (error) {
      console.error('Error adding note:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteNote = async (noteId: number) => {
    try {
      setLoading(true);
      await axiosInstance.delete(`${config.notesApiUrl}/notes/${noteId}`);
      setNotes(notes.filter(note => note.id !== noteId));
    } catch (error) {
      console.error('Error deleting note:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">Device Notes</h1>

      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Select Device</label>
        <select
          value={selectedDeviceId ?? ''}
          onChange={(e) => setSelectedDeviceId(e.target.value ? parseInt(e.target.value) : null)}
          className="w-full p-2 border rounded-md"
        >
          <option value="">-- Select a device --</option>
          {devices.map((device) => (
            <option key={device.device_id} value={device.device_id}>
              {device.name}
            </option>
          ))}
        </select>
      </div>

      {selectedDeviceId && (
        <div className="mb-6">
          <label className="block text-sm font-medium mb-2">Add Note</label>
          <div
            className={`space-y-4 ${dragActive ? 'border-2 border-dashed border-blue-400' : ''}`}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            <textarea
              value={newNoteText}
              onChange={(e) => setNewNoteText(e.target.value)}
              className="w-full p-2 border rounded-md"
              rows={4}
              placeholder="Write your note here..."
            />

            <div className="flex items-center space-x-2">
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileSelect}
                accept="image/*"
                className="hidden"
              />
              <button
                onClick={handleSelectFileClick}
                className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300"
                type="button"
              >
                Select Image
              </button>
              {imagePreview && (
                <div className="relative w-48">
                  <img
                    src={imagePreview}
                    alt="Preview"
                    className="w-full h-auto rounded-md"
                  />
                  <button
                    onClick={handleImageClear}
                    className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full hover:bg-red-600"
                  >
                    <FaTrash size={12} />
                  </button>
                </div>
              )}
            </div>

            <div className="flex flex-col space-y-2">
              <label className="flex items-center space-x-2 text-sm">
                <input
                  type="checkbox"
                  checked={filterChanged}
                  onChange={(e) => setFilterChanged(e.target.checked)}
                  className="h-4 w-4 text-blue-500"
                />
                <span>Filter Changed</span>
              </label>
              <div className="flex items-center space-x-2">
                <FaCalendar className="text-gray-500" />
                <DatePicker
                  selected={selectedDateTime}
                  onChange={(date) => setSelectedDateTime(date)}
                  showTimeSelect
                  dateFormat="yyyy-MM-dd HH:mm:ss"
                  className="border rounded-md p-2"
                />
              </div>
            </div>
            <button
              onClick={handleAddNote}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              disabled={loading || newNoteText.trim() === ''}
            >
              Add Note
            </button>
          </div>
        </div>
      )}

      {selectedDeviceId && notes.length > 0 && (
        <div>
          <h2 className="text-xl font-semibold mb-4">Notes for Device</h2>
          <ul className="space-y-4">
            {notes.map((note) => (
              <li key={note.id} className="border p-4 rounded-md flex justify-between items-start">
                <div className="flex-grow">
                  <div className="flex items-center gap-3 text-sm text-gray-600 mb-1">
                    <span>{formatLocalDateTime(note.created_at)}</span>
                    <span className="flex items-center text-gray-500">
                      <FaUser className="mr-1" />
                      {note.creator_email}
                    </span>
                    {note.filter_changed && (
                      <span className="flex items-center text-blue-500">
                        <FaFilter className="mr-1" /> Filter Changed
                      </span>
                    )}
                  </div>
                  <p className="whitespace-pre-wrap">{note.note_text}</p>
                  {note.image_url && (
                    <img
                      src={note.image_url}
                      alt="Note attachment"
                      className="mt-2 max-h-48 w-auto rounded-md cursor-pointer"
                      onClick={() => note.image_url ? setSelectedImage(note.image_url) : null}
                    />
                  )}
                </div>
                <button
                  onClick={() => handleDeleteNote(note.id)}
                  className="text-red-500 hover:text-red-700 ml-4"
                >
                  <FaTrash />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {selectedDeviceId && notes.length === 0 && !loading && (
        <p className="text-gray-500">No notes for this device.</p>
      )}

      {loading && <p className="text-gray-500">Loading...</p>}

      {selectedImage && (
        <ImageModal
          imageUrl={selectedImage}
          alt="Expanded view"
          onClose={() => setSelectedImage(null)}
        />
      )}
    </div>
  );
};

export default DeviceNotes;