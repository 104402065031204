// src/components/Navbar/Navbar.tsx
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext'; // Use the auth context
import './Navbar.css'; // Import the navbar styles

const Navbar: React.FC = () => {
  const { isLoggedIn, logout } = useAuth();

  if (!isLoggedIn) return null; // Do not render navbar if not logged in

  return (
    <div className="navbar">
      <h2 className="navbar-title">FilterSense</h2>
      <nav className="navbar-links">
        <NavLink to="/analyze" className="nav-item">
          Analyze
        </NavLink>
        <NavLink to="/edit-device" className="nav-item">
          Device List
        </NavLink>
        <NavLink to="/notes" className="nav-item">
          Notes
        </NavLink>
      </nav>
      <button className="logout-button" onClick={logout}>
        Logout
      </button>
    </div>
  );
};

export default Navbar;